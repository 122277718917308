import styled from 'styled-components';

import media from '@/modules/media';

export const Wrapper = styled.div<{ mode?: 'modalView' | 'normalView' }>`
  max-width: ${(props) => (props.mode === 'modalView' ? '100%' : '536px')};
  margin: auto;
  padding-bottom: ${(props) => (props.mode === 'modalView' ? 0 : '100px')};
  display: flex;
  flex-direction: column;
`;

export const RegistrationLayoutContainer = styled.div``;

export const RegistrationLayoutWrapper = styled.div`
  display: flex;
  height: 100%;

  ${media.tabletLandscape} {
    flex-direction: column;
    gap: 24px;
  }
`;
export const RegistrationLayoutLeft = styled.div`
  max-width: calc(
    100% - ${(props) => props.theme.loginRegisterDrawerSize.desktop}
  );
  width: 100%;

  ${media.tabletLandscape} {
    max-width: 100%;
  }
`;
export const RegistrationLayoutRight = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  width: ${(props) => props.theme.loginRegisterDrawerSize.desktop};
  height: 100%;

  ${media.tabletLandscape} {
    position: relative;
    width: 100%;
  }
`;
export const StudyGuideWrapper = styled.div<{ $isStudyGuideOpen: boolean }>`
  position: fixed;
  right: ${(props) => props.theme.rightSidebarWidth.desktop};
  background-color: ${(props) => props.theme.color.white};
  flex-direction: column;
  display: flex;
  width: ${(props) => props.theme.examsReviewSidebarWidth.desktop};
  height: 100%;
  border-right: 1px solid ${(props) => props.theme.color.gray200};
  padding-top: ${(props) => props.theme.space.lgx};
  z-index: 2;

  ${media.smallLaptop} {
    width: ${(props) => props.theme.examsReviewSidebarWidth.mobile};
    transform: ${(props) =>
      props.$isStudyGuideOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;
    position: ${(props) => (props.$isStudyGuideOpen ? 'fixed' : 'absolute')};
    z-index: ${(props) => props.$isStudyGuideOpen && '20'};
    right: ${(props) => props.theme.rightSidebarWidth.mobile};
  }

  ${media.custom(860)} {
    width: ${(props) => props.theme.examsReviewSidebarWidth.mobile};
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    right: 0;
    transform: ${(props) =>
      props.$isStudyGuideOpen ? 'translateX(0)' : 'translateX(100%)'};
    position: ${(props) => (props.$isStudyGuideOpen ? 'fixed' : 'absolute')};
    z-index: ${(props) => props.$isStudyGuideOpen && '20'};
    padding-bottom: 40px;
  }

  & > div:first-child {
    justify-content: flex-start;
    margin-left: ${(props) => props.theme.space.xxl};
    margin-right: ${(props) => props.theme.space.xxl};
    padding-bottom: ${(props) => props.theme.space.mdl};
    border-bottom: 1px solid ${(props) => props.theme.color.gray200};
  }

  ${media.tabletLandscape} {
    & > div:last-child > div:last-child {
      padding-right: ${(props) => props.theme.space.xxl};
    }
  }

  ${media.custom(600)} {
    width: 100%;
  }
`;

export const RegistrationTitle = styled.div<{ marginTop?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.font.xxl};
  font-weight: 900;
  color: ${(props) => props.theme.color.gray900};
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : '104px'};
  margin-bottom: ${(props) => `${props.theme.space.xxl}`};
  text-transform: uppercase;
  text-align: center;

  ${media.tablet} {
    font-size: ${(props) => props.theme.font.xxl};
  }
`;

export const ProgressBarWrapper = styled.div<{
  mode?: 'normalView' | 'modalView';
}>`
  margin-bottom: 58px;
  padding-right: ${(props) => (props.mode === 'modalView' ? '27px' : '17px')};
  padding-left: ${(props) => (props.mode === 'modalView' ? '18px' : '10px')};

  & > div > div span {
    font-size: ${(props) => props.theme.font.sm};
    white-space: nowrap;
  }
`;

export const NavigationButtons = styled.div<{ $isModalView?: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.$isModalView ? 'space-between' : 'center'};
  width: 100%;
  gap: ${(props) => (props.$isModalView ? 0 : props.theme.space.xl)};
  margin-top: ${(props) => (props.$isModalView ? props.theme.space.xxl : 0)};
`;

export const PayButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.space.md};
`;

export const Form = styled.form<{ mode?: 'modalView' | 'normalView' }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.xl};
  margin-top: ${(props) => props.theme.space.xl};
`;

export const LoginForm = styled.form<{ mode?: 'modalView' | 'normalView' }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.space.xl};
`;

export const SignInOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const SeparationLine = styled.div`
  display: flex;
  align-items: center;
  line-height: normal;
  margin: 32px 0 0;
  color: ${(props) => props.theme.color.gray600};
  font-weight: 300;

  &::before,
  &::after {
    content: '';
    display: flex;
    flex: 1 1;
    height: 1px;
    background-color: ${(props) => props.theme.color.gray300};
    margin: auto;
  }

  &::before {
    margin-right: ${(props) => props.theme.space.xl};
  }

  &::after {
    margin-left: ${(props) => props.theme.space.xl};
  }
`;

export const SignInGoogleText = styled.span`
  font-size: ${(props) => props.theme.font.md};
  font-weight: 600;
`;
export const SignInMicrosoftText = styled.span`
  font-size: ${(props) => props.theme.font.md};
  font-weight: 600;
`;

export const SignInAppleText = styled.span`
  font-size: ${(props) => props.theme.font.md};
`;

export const ChangeSignInOption = styled.p<{ $hasMarginBottom?: boolean }>`
  display: flex;
  gap: ${(props) => props.theme.space.md};
  font-size: ${(props) => props.theme.font.md};
  margin-top: ${(props) => props.theme.space.lg};
  margin-bottom: ${(props) => props.$hasMarginBottom && '64px'};
`;

export const ActionText = styled.p`
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.primary700};
  font-weight: 700;
  cursor: pointer;
`;

export const Success = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  font-size: ${(props) => props.theme.font.md};
  color: ${(props) => props.theme.color.success700};
  margin-top: -${(props) => props.theme.font.xl};
`;

export const DividerLine = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.color.gray200};
`;
