import { css } from 'styled-components';

export const shadowBoxStyles = css`
  background: linear-gradient(92.54deg, #ffffff 0%, #f6faff 100%);
  border: 2px solid ${(props) => props.theme.color.blue10};
  border-radius: 24px;
`;
export const shadowBoxBlueStyles = css`
  background: linear-gradient(135deg, #ffffff 0%, #f4f8fe 100%);
  border: 2px solid ${(props) => props.theme.color.blue10};
  border-radius: 24px;
`;
export const shadowBoxRedStyles = css`
  background: linear-gradient(92.54deg, #ffffff 0%, #f9f4fd 100%);
  border: 2px solid #f9f4fd;
  border-radius: 24px;
`;

export const shadowBoxOrangeStyles = css`
  background: linear-gradient(135deg, #ffffff 0%, #fef5ed 100%);
  border: 2px solid #fef5ed;
  border-radius: 24px;
`;
export const shadowBoxWithBorder = css`
  border: 2px solid ${(props) => props.theme.color.gray25};
  box-shadow: 0px 0px 16px 0px #d0d2d84d;
  padding: 24px;
  border-radius: 24px;
`;
